import { createGlobalStyle } from "styled-components";
import styled from "styled-components";

export const ContractAddress = styled.p`
  color: white;
  font-family: "Motiva Sans Light", sans-serif;
  text-size-adjust: 70%;
  text-align: center;
  margin-top: 1rem;

  @media only screen and (max-width: 890px) {
    max-width: 100%;
    font-size: 0.8rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 0.6rem;
  }
`;

export const ContractAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CopyButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 8px;
  outline: none;
  max-width: 5%;

  svg {
    width: 16px;
    height: 16px;
    fill: #fff;
    transition: fill 0.3s;

    &:hover {
      fill: #ff8c00;
    }
  }
`;

export const Disclaimer = styled.p`
  color: white;
  font-family: "Motiva Sans Bold", sans-serif;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const Styles = createGlobalStyle`
    @font-face {
        font-family: "Motiva Sans Light";
        src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Motiva Sans Bold";
        src: url("/fonts/NewsoftheWorldwide.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "News of the WorldWide";
        src: url("/fonts/NewsoftheWorldwide.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "News of the WorldWide Italic";
        src: url("/fonts/NewsoftheWorlditalic.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Helvetica";
        src: url("/fonts/Helvetica.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Helvetica Bold";
        src: url("/fonts/Helvetica-Bold.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Helvetica BoldOblique";
        src: url("/fonts/Helvetica-BoldOblique.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Helvetica Light";
        src: url("/fonts/helvetica-light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Helvetica Oblique";
        src: url("/fonts/Helvetica-Oblique.ttf") format("truetype");
        font-style: normal;
    }

    body,
    html,
    a {
        font-family: 'Motiva Sans Light', sans-serif;
    }

    body {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        background: #4b9540;
        overflow-x: hidden;
    }

    a:hover {
        color: #FF8C00;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: #FFFFFF;
        transition: all 0.3s ease-in-out;
        outline: none;
        width: 100%;
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #7FFF00 0px 0px 0px 1px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Motiva Sans Bold', serif;
        color: #000000;
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
            font-size: 47px;
        }

        @media only screen and (max-width: 414px) {
            font-size: 32px;
        }
    }

    p {
        color: #000000;
        font-size: 18px;
        line-height: 1.35;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #FF8C00;

        :hover {
            color: #FF8C00;
        }
    }

    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 0.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }
`;