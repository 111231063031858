import styled from "styled-components";

export const StyledSvgIcon = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 95%;
  max-height: 95%;

    @media screen and (max-width: 768px) {
        max-width: 100%;
        max-height: 100%;
    }
`;