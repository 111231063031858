import { useState } from "react";
import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { SocialLink } from '../Footer/styles';
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  ButtonAndIconsWrapper,
  Burger,
  NotHidden,
  StyledDrawer,
  Menu,
  CustomNavLinkSmall,
  Label,
  IconWrapper,
  Outline,
  Span,
} from "./styles";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("about")}>
          <Span>Lowdown</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("mission")}>
          <Span>Pledge</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("product")}>
          <Span>Experience</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("roadmap")}>
          <Span>Plan</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollTo("game")}
        >
          <Span>
            <ButtonAndIconsWrapper>  
              <Button>Play Game</Button>
              <IconWrapper>
                <SocialLink href="https://twitter.com/PickyBC">
                  <SvgIcon src="twitterx.svg" width="25px" height="25px" />
                </SocialLink>
                <SocialLink href="https://t.me/pickybc">
                  <SvgIcon src="telegram.svg" width="26px" height="26px" />
                </SocialLink>
              </IconWrapper>
            </ButtonAndIconsWrapper>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.svg" width="141px" height="84px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <StyledDrawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu></Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </StyledDrawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);