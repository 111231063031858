import { StyledButton } from "./styles";
import { ButtonProps } from "../types";

export const Button = ({
  color,
  fixedWidth,
  children,
  onClick,
  href,
}: ButtonProps & { href?: string }) => {
  if (href) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        <StyledButton color={color} fixedWidth={fixedWidth} onClick={onClick}>
          {children}
        </StyledButton>
      </a>
    );
  }

  return (
    <StyledButton color={color} fixedWidth={fixedWidth} onClick={onClick}>
      {children}
    </StyledButton>
  );
};